import React, { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import { MessagingSDK, AppType } from '@rakuten-rewards/messaging-sdk-js'

const messagingSDK = new MessagingSDK()

function App() {
  const [member, setMember] = useState<{
    guid?: string
    anonymous_id?: string
  }>({
    guid: '1001001',
    anonymous_id: '1000010000',
  })

  const [config, setConfig] = useState<{
    batchSize: number
    lingerDuration: number
  }>({
    batchSize: 10,
    lingerDuration: 1 * 5 * 1000,
  })

  React.useEffect(() => {
    messagingSDK.init({
      hostApp: {
        name: 'sdk-demo',
        version: '1.1.1',
        type: AppType.Web,
      },
      networkConfig: {
        usa: {
          messageGatewayBaseURL: 'https://qa-message-gateway.rrcbsn.com/',
          batchSize: config.batchSize,
          lingerDuration: config.lingerDuration,
        },
      },
      regionId: 'usa',
      invalidMessageHandler: (message: any, error: any) => {
        console.group('Message Validation Failed')
        console.log({ message })
        console.log({ error })
        console.groupEnd()
      },
    })
  }, [config.batchSize, config.lingerDuration])

  useEffect(() => {
    messagingSDK.setMember(member)
  }, [member])

  function handleSubmit(e: any) {
    e.preventDefault()
    const schema_id = +e.target.elements['schema_id'].value
    const type = e.target.elements['type'].value
    const data = e.target.elements['data'].value
    const parsedData = data ? JSON.parse(data) : null

    messagingSDK.send<Object>({
      type,
      schema_id,
      ...(parsedData && { data: parsedData }),
    })
  }

  function handleMemberDataChange(e: any) {
    const key = e.target.name
    let value = e.target.value
    // if (key === 'groups') {
    //   value = value.split(',')
    // }
    // if (key === 'id') {
    //   value = +value
    // }
    setMember({ ...member, [key]: value })
  }

  function handleUpdateConfigs(e: any) {
    e.preventDefault()
    const lingerDuration = +e.target.elements['lingerDuration'].value
    const batchSize = +e.target.elements['batchSize'].value
    setConfig({ lingerDuration, batchSize })
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Message SDK Demo App</p>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          <form onSubmit={handleUpdateConfigs}>
            <Field label="Batch Size" defaultValue={config.batchSize} placeholder="batch size" name="batchSize" type="number" />
            <Field
              label="Linger Duration in ms"
              defaultValue={config.lingerDuration}
              placeholder="Linger duration"
              name="lingerDuration"
              type="number"
            />
            <button type="submit">Update configs</button>
          </form>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <h2>member Data</h2>
            {/* <Field label="member ID" value={member?.id} placeholder="Member ID" name="id" onChange={handleMemberDataChange} /> */}
            <Field label="Member GUID" value={member?.guid} placeholder="Member GUID" name="guid" onChange={handleMemberDataChange} />
            {/* <Field
              label="Member Account ID"
              value={member?.account_id}
              placeholder="Member Account ID"
              name="account_id"
              onChange={handleMemberDataChange}
            /> */}
            <Field
              label="Member Anonymous ID"
              value={member?.anonymous_id}
              placeholder="Member Anonymous ID"
              name="anonymous_id"
              onChange={handleMemberDataChange}
            />
            {/* <Field
              label="groups: comma separated: 1,2,3"
              value={member?.groups}
              placeholder="Member Groups"
              name="groups"
              onChange={handleMemberDataChange}
            /> */}
          </div>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '5%' }}>
            <Field label="Schema ID" defaultValue={116} placeholder="schema id" name="schema_id" type="number" />
            <Field label="Event Type" defaultValue={'Surface Visited'} placeholder="event type" name="type" />
            <textarea defaultValue="" placeholder="event data(JSON)" name="data" rows={20} />
            <button type="submit">Send event</button>
          </form>
        </div>
      </header>
    </div>
  )
}

function Field(props: any) {
  const { label, ...rest } = props
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
      <label style={{ fontSize: '1rem' }}>{label}</label>
      <input {...rest} />
    </div>
  )
}
export default App
