import * as idb from 'idb-keyval'
import { v4 as uuid } from 'uuid'
import { throttle } from 'throttle-debounce'
import { UAParser, UAParserInstance } from 'ua-parser-js'

import { getParentPath, getVisitID, Device, postData, getSearchParams, getWindow } from './utils'

export enum AppType {
  Web = 'WEB',
  Extension = 'BROWSER_EXTENSION',
}

interface Member {
  id?: number
  guid?: string
  account_id?: string
  anonymous_id?: string
  previous_anonymous_ids?: string[]
  groups?: string[]
  server_session_id?: string
}

interface Application {
  name: string
  version: string
  type: AppType
  labels?: string[]
}

interface NetworkConfig {
  messageGatewayBaseURL: string
  batchSize: number
  lingerDuration: number
}

interface Config {
  hostApp: Application
  networkConfig: Record<string, NetworkConfig>
  regionId: string
  invalidMessageHandler: (message: PartialMessage<unknown> | undefined, error: any) => void
  integrations?: Record<string, Record<string, string | number | boolean>>
}

interface DefaultPage {
  url: string
  host: string
  path: string
  parent_path?: string
  query_params?: Record<string, string>
  title?: string
}

interface Page extends DefaultPage {
  labels?: string[]
  variation?: string
}

interface Component {
  id: string
  type: string
  parent?: Component
  labels?: string[]
  variation?: string
}

interface Screen {
  name: string
  labels?: string[]
  variation?: string
}

export interface PartialMessage<T> {
  schema_id: number
  type: string
  data?: T
  parentMessageId?: string
  sourceTimestampMillis?: number
  component?: Component
  screen?: Screen
  page?: Page
  serverContext?: unknown
  experiments?: Record<string, object>
}

interface OperatingSystem {
  name?: string
  version?: string
}

interface Origin {
  app: Application
  os?: OperatingSystem
  user_agent?: string
  client_agent?: string
  page?: Page
  screen?: Screen
  component?: Component
  referrer?: string
}

interface ClientContext {
  device?: Device
  experiments?: unknown
  locale?: string
  server_session_id?: string
  origin: Origin
  region_id?: string
}

interface Message<ClientContextType> extends PartialMessage<unknown> {
  id: string
  visit_id: string | null
  member: Member | null
  server_context: unknown | null
  client_context: ClientContextType
  source_time_zone: string
  source_created_at_millis: number
  source_timestamp_millis: number
  app_start_id: string
  server_session_id?: string
  issuer: Application | null
  partialMessage?: PartialMessage<unknown>
}

interface ValidationError {
  message_id: string
  error_code: string
  error_message: string
}

interface ValidationErrorResponse {
  data: ValidationError[]
}

class MessagingSDK {
  private config: Config
  private member: Member | null
  private messagesCounter: number = 0
  private timer: ReturnType<typeof setInterval>
  private uaParser: UAParserInstance
  private iSFlushInProgress: boolean
  private throttleFlush
  private started: boolean = false
  private window: Window | undefined
  private serverSessionId: string | undefined

  constructor() {
    this.sendMessagesToMessageGateway = this.sendMessagesToMessageGateway.bind(this)
    this.flush = this.flush.bind(this)
    this.throttleFlush = throttle(1000, this.flush)
  }

  public init(config: Config): void {
    this.config = config
    this.window = getWindow()
    this.uaParser = new UAParser(this.window?.navigator?.userAgent)
    this.started = true
    this.throttleFlush()
    this.restartTimer()
  }

  public setRegion(id: string): void {
    if (this.config) {
      this.config.regionId = id
    }
  }

  public setMember(member: Member | null) {
    if (member) {
      const { server_session_id, ...rest } = member
      this.member = rest
      this.serverSessionId = server_session_id
    }
  }

  public send<T>(partialMessage: PartialMessage<T>): void {
    const message: Message<ClientContext | null> = this.createMessage(partialMessage)
    idb
      .set(message.id, message)
      .then(() => {
        this.messagesCounter++
        if (this.config && this.messagesCounter >= this.regionNetworkConfig.batchSize && this.started) {
          this.throttleFlush()
        }
      })
      .catch((error) => {
        // if something went wrong, send it directly to the API
        this.sendMessagesToMessageGateway([message])
      })
  }

  private getDefaultPageProperties(): DefaultPage {
    const url = new URL(decodeURIComponent(this.window?.location.href ?? ''))
    const parent_path = getParentPath(url.pathname)
    const searchParams = getSearchParams()
    const query_params = Object.fromEntries(searchParams)
    return {
      url: url.href,
      host: url.host,
      path: url.pathname,
      title: document?.title,
      parent_path,
      query_params,
    }
  }

  private createMessage(partialMessage: PartialMessage<unknown>): Message<ClientContext | null> {
    const id = uuid()
    const visit_id = getVisitID()
    const member = this.member
    const server_context = partialMessage.serverContext
    const client_context = this.started ? this.getClientContext(partialMessage) : null
    const { schema_id, type, data, parentMessageId } = partialMessage
    const source_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const source_created_at_millis = new Date().getTime()
    // if not sent in partial message it's same as source_created_millis
    const source_timestamp_millis = partialMessage?.sourceTimestampMillis ?? source_created_at_millis
    const issuer = this.started ? this.config.hostApp : null

    return {
      id,
      visit_id,
      member,
      server_context,
      client_context,
      schema_id,
      type,
      data,
      parentMessageId,
      source_time_zone,
      source_created_at_millis,
      source_timestamp_millis,
      app_start_id: 'UNKNOWN',
      // server_session_id: this?.serverSessionId,
      issuer,
      ...(!this.started && { partialMessage }),
    }
  }

  private getClientContext(partialMessage: PartialMessage<unknown>): ClientContext {
    const device = this.getDevice()
    const experiments = partialMessage?.experiments
    const locale = this.window?.navigator.language
    const server_session_id = this?.serverSessionId
    const origin = this.getOrigin(partialMessage)
    // temporary commenting
    // const region_id = this.config.regionId

    return {
      device,
      experiments,
      locale,
      server_session_id,
      origin,
      // region_id,
    }
  }

  private getOrigin(partialMessage: PartialMessage<unknown>): Origin {
    const app = this.config.hostApp
    const os = this.uaParser.getOS()
    const user_agent = this.window?.navigator.userAgent
    const pageInformationProvidedByDeveloper = partialMessage?.page ?? {}
    const page = { ...pageInformationProvidedByDeveloper, ...this.getDefaultPageProperties() }
    const screen = partialMessage?.screen
    const component = partialMessage?.component
    const referrer = document.referrer

    return {
      app,
      os,
      user_agent,
      page,
      screen,
      component,
      referrer,
    }
  }

  private getDevice(): Device {
    const uaDevice = this.uaParser.getDevice()
    return {
      type: uaDevice.type,
      model: uaDevice.model,
      manufacturer: uaDevice.vendor,
    }
  }

  private async sendMessagesToMessageGateway(messages: PartialMessage<unknown>[]): Promise<unknown> {
    const baseURL: string = this.config.networkConfig[this.config.regionId].messageGatewayBaseURL
    return postData(`${baseURL}/message/v1/regions/${this.config.regionId}/messages/batch`, { messages })
  }

  private restartTimer(): void {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.timer = setInterval(() => {
      this.throttleFlush()
    }, this.regionNetworkConfig.lingerDuration)
  }

  public flush(): void {
    // if it's ready start flushing, otherwise ignore
    if (this.started) {
      if (!this.iSFlushInProgress) {
        this.iSFlushInProgress = true
        idb.values().then(async (messages: Message<ClientContext | null>[]) => {
          if (messages.length > 0) {
            const messagesToSend = this.updateClientContext(messages)
            this.sendMessagesToMessageGateway(messagesToSend)
              .then(async (response: Response) => {
                const hasResponseBody = response.headers.get('content-type')?.includes('application/json')
                if (response.ok) {
                  // successful API call
                  if (hasResponseBody) {
                    // validation failed, more data available regarding the errors
                    const errorResponse: ValidationErrorResponse = hasResponseBody ? await response.json() : null
                    errorResponse.data?.forEach((failedMessage: ValidationError) => {
                      const message = messages.find((message: Message<ClientContext | null>) => message.id === failedMessage?.message_id)
                      this.config.invalidMessageHandler(message, failedMessage)
                    })
                  }
                  this.restartTimer()
                  this.removeMessagesFromStorage(messages)
                } else {
                  // other API errors happened, we should not remove the messages from the storage
                  console.log(response)
                }
              })
              .catch((error) => {
                console.error('Network error', error)
              })
          }
          this.iSFlushInProgress = false
        })
      }
    }
  }

  private updateClientContext(messages: Message<ClientContext | null>[]): Message<ClientContext>[] {
    // we use this function to update the messages that were created and saved before init ready
    const updatedMessages: Message<ClientContext>[] = []
    messages.forEach((message: Message<ClientContext | null>) => {
      if (message.client_context) {
        // ready to go
        updatedMessages.push(<Message<ClientContext>>message)
      } else {
        // the message was created before init, update it
        const client_context = this.getClientContext(<PartialMessage<unknown>>message.partialMessage)
        delete message.partialMessage
        updatedMessages.push({ ...message, client_context, issuer: this.config.hostApp })
      }
    })

    return updatedMessages
  }

  private async removeMessagesFromStorage(messages: PartialMessage<unknown>[]): Promise<void> {
    const messageIDs = messages.map((message: Message<ClientContext>) => message.id)
    await idb.delMany(messageIDs)
    this.messagesCounter = 0
  }

  private get regionNetworkConfig(): NetworkConfig {
    return this.config.networkConfig[this.config.regionId]
  }
}

export default MessagingSDK
