export interface Device {
  model?: string
  type?: string
  manufacturer?: string
}

const VISIT_ID_LOCAL_STORAGE_KEY = 'RAKUTEN_VISIT_ID'

export function isBrowser(): boolean {
  return typeof window !== 'undefined'
}
export function getParentPath(pathname: string): string | undefined {
  const normalizedPathname = pathname.substring(0, pathname.lastIndexOf('/'))
  const [_, parentPathname] = normalizedPathname.split('/')
  return parentPathname ? `/${parentPathname}/` : undefined
}

export function getVisitID(): string | null {
  if (isBrowser()) {
    return localStorage.getItem(VISIT_ID_LOCAL_STORAGE_KEY)
  } else {
    return null
  }
}

export async function postData(url = '', data = {}) {
  return await fetch(url, {
    method: 'POST',
    mode: 'cors',
    referrerPolicy: 'no-referrer',
    headers: {
      'Content-Type': 'application/json',
      'client-ts': new Date().getTime().toString(),
      'client-agent': 'unknown',
    },
    body: JSON.stringify(data),
  })
}

export function getSearchParams(): URLSearchParams {
  const url = new URL(decodeURIComponent(window.location.href))
  return new URLSearchParams(url.search)
}

export function getWindow(): Window | undefined {
  return isBrowser() ? window : undefined
}
